<template>
  <div>
    <div class="eoHead position-absolute top-50 start-50 translate-middle">{{ title }}</div>
  </div>
</template>

<script>
export default {
  name: "App",
  data() {
    return {
      title: "Elektro Oberland",
    };
  },
};
</script>

<style>
#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  margin-top: 60px;
}
.eoHead {
  font-size: 500%; 
}
</style>
